<template>
  <v-container>

    <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="tableName,id" sort-by="tableName,id"
      mobile-breakpoint="3" class="table-cursor" :search="search" :loading="loading_status" loader-height="5"
      loading-text="Cargando ...">
      <template v-slot:top>
        <h1>Configuración</h1>
        <v-spacer></v-spacer>
        <v-btn @click="get_data">TEST</v-btn>
      </template>

    </v-data-table>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import createEmpresa from "../../utils/fe_empresa.js";
import { fe_ws } from "../../services/webserver.js";
import createSetting from "../../utils/createSetting.js";
export default {
  components: {},
  mounted() {
    this.get_items();
    //this.test('taxes');
  },
  data() {
    return {
      tab: null,
      loading_status: false,
      search: '',
      empresa: createEmpresa(),
      items: [],
      headers: [
        {
          text: "Tabla",
          align: "start",
          sortable: true,
          filterable: true,
          value: "tableName",
          dataType: "text",
        },
        {
          text: "ID",
          align: "start",
          sortable: true,
          filterable: true,
          value: "id",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          filterable: true,
          value: "name",
          dataType: "text",
        },
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "code",
          dataType: "text",
        },
        {
          text: "Borrado",
          align: "start",
          sortable: true,
          value: "deleted_at",
          dataType: "text",
        },
        {
          text: "Creado",
          align: "start",
          sortable: true,
          value: "created_at",
          dataType: "text",
        },
        {
          text: "Actualizado",
          align: "start",
          sortable: true,
          value: "updated_at",
          dataType: "text",
        },
      ],
      table_lst: ['type_documents', 'taxes', 'events', 'countries', 'languages', 'discounts', 'type_times', 'type_users', 'tax_details', 'departments', 'type_workers', 'type_regimes', 'unit_measures', 'payment_forms', 'type_coverages',
        'municipalities', 'type_contracts', 'type_deliveries', 'type_currencies', 'payroll_periods', 'type_operations', 'type_principals', 'payment_methods', 'subtype_workers', 'type_liabilities', 'reference_prices', 'type_incapacities', 'type_environments', 'type_payroll_notes', 'type_mandate_times', 'type_organizations', 'rejection_concepts', 'type_event_payments', 'type_scope_mandates', 'correction_concepts', 'type_nature_mandates', 'type_operation_events',
        'type_endorsement_events','type_document_references', 'organization_provenances', 'type_item_identifications', 'type_document_identifications', 'type_item_sector_identifications'],
      tablas: [],
      settings: createSetting(),
    };
  },
  methods: {
    // get_items() {
    //   var qry = {
    //     table: "settings",
    //     filters: [],
    //   };
    //   this.loading_status = true;
    //   fe_ws("get_table", qry, (data) => {
    //     console.log(data);
    //     this.loading_status = false;
    //     this.items = data;

    //   });
    // },
    async get_items() {
      this.items = [];
      var qry = {};
      var tbl = '';

      for (tbl of this.table_lst) {
        this.loading_status = true;
        if (tbl) {
          qry = {
            table: 'settings',
            filters: [
              {
                field: "tableName",
                operator: "=",
                value: tbl,
              },
            ]
          }

          let promise = new Promise((resolve, reject) => {
            fe_ws(
              "get_table",
              qry,
              function (data) {
                resolve(data);
              },
              function () {
                reject([]);
              }
            );
          });
          let result = await promise;
          if (result) {
            this.items.push.apply(this.items, result);
            //console.log(result);
          }
        }
      }
      this.loading_status = false;
    },
    async get_data() {
      this.items = [];
      var qry = {};
      var tbl = '';
      for (tbl of this.table_lst) {
        this.loading_status = false;
        qry = { tables: tbl };
        this.loading_status = true;
        let promise = new Promise((resolve, reject) => {
          // fe_ws("get_listings", qry, (data) => {
          //   var dta = JSON.parse(data);
          //   dta = dta[tbl];
          //   dta.forEach((rg) => {
          //     delete rg.id;
          //     rg.tableName = tbl;
          //   });
          //   this.inser2tablest(dta);
          // });
          fe_ws(
            "get_listings",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });
        let result = await promise;
        var dta = JSON.parse(result);

        dta = dta[tbl];
        dta.forEach((rg) => {
          rg.tableName = tbl;
        });
        this.inser2tablest(dta);
        this.loading_status = false;
        // 
      }
    },
    // test(fld) {
    //   var qry = { tables: fld };
    //   fe_ws("get_listings", qry, (data) => {
    //     var dta = JSON.parse(data);
    //     dta = dta[fld];
    //     dta.forEach((rg) => {
    //       delete rg.id;
    //       rg.tableName = fld;
    //     });
    //     this.inser2tablest(dta);
    //   });
    // },
    inser2tablest(dt) {
      var qry = {
        table: 'settings', data: dt
      };
      console.log(qry);
      fe_ws("put_to_table", qry, (data) => {
        console.log(data);
      });
    },

  }
};
</script>

<style>
</style>