export default (data = {}) => {
    return {
        type_document_identification_id: 6,
        type_organization_id: 1,
        type_regime_id: 1,
        type_liability_id: 29,
        business_name: "EMPRESA DE PRUEBAS",
        merchant_registration: "1234567-12",
        municipality_id: 1006,
        address: "CALLE 1 1C 1",
        phone: 3216547,
        pos: '',
        email: "test@test.test",
        id: null,
        pin: null,
        url: null,
        certificate: null,
        password: null,
        technical_key: null,
        type_environment_id: 2,
        imap_host: "imap.domail.com.co",
        imap_port: 993,
        imap_username: "test",
        imap_password: "secret",
        smtp_host: "imap.domail.com.co",
        smtp_port: 993,
        smtp_username: "test",
        smtp_password: "secret",
        smtp_encryption: "tls",
        smtp_from_address: "test@test.com",
        smtp_from_name: "Test",
        ...data
    }
}


